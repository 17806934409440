<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn @click="$router.go(-1)" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>Статистика продаж</v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- <template v-slot:extension>
        <v-tabs centered grow>
          <v-tab :to="{name: 'CabinetStatistics'}">Подробно</v-tab>
          <v-tab :to="{name: 'CabinetStatisticsDay'}">По дням</v-tab>
        </v-tabs>
      </template> -->
    </v-toolbar>
    <v-container v-if="!loadPage">
      <v-row class="align-center">
        <v-col v-if="books.length > 0" cols="12" sm="5">
          <v-select
            v-model="selectBook"
            :items="books"
            item-text="title"
            item-value="id"
            label="Книга"
            prepend-icon="$vuetify.icons.bookshelf"
          ></v-select>
        </v-col>
        <v-col cols="12" sm="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :min-width="$vuetify.breakpoint.xsOnly ? '100%' : '290px'"
          >
            <!-- :return-value.sync="date" -->
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateRangeText"
                label="Дата"
                prepend-icon="$vuetify.icons.calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="date"
              no-title
              type="month"
              :full-width="$vuetify.breakpoint.xsOnly"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">Закрыть</v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date)">Принять</v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12" sm="3">
          <v-btn dark color="primary" @click="sortStatistics">Искать</v-btn>
        </v-col>
      </v-row>
      <v-row v-if="!statistics || statistics.length == 0" align="center">
        <no-content route-name title="Данных нет"></no-content>
      </v-row>
      <v-data-table
        v-else
        :headers="headersTable"
        :items="statistics"
        :items-per-page="15"
        class=""
        item-key="id_table"
      >
      <template v-slot:body.append>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td class="text-center">Итого: {{all_count}}</td>
          <td class="green--text">{{all_honorarium}} р.</td>
          
        </tr>
      </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headersTable: [
      {
        text: "День",
        align: "start",
        sortable: false,
        value: "day_date"
      },
      { text: "Книга", value: "book_title" },
      { text: "Подписка/Продажа", value: "book_finish" },
      // { text: "Цена", value: "book_price" },
      { text: "Кол-во", value: "count", align: "center", },
      { text: "Гонорар", value: "honorarium" }
    ],
    
    selectBook: "all",
    date: "",
    menu: false,
  }),
  methods: {
    getStatistics() {
      let app = this;
      this.$store
        .dispatch("cabinet_statistics/getStatistics", {
          book_id: app.selectBook,
          date: app.date,
        });
    },
    goPage(nextPage) {
      // console.log('nextPage',nextPage);
      //   console.log(this.$route.query.page);
      if (nextPage != this.$route.query.page) {
        this.$router.push({
          name: this.$route.name,
          //   params: { id: this.bookId },
          query: { book_id: this.selectBook, page: nextPage, date: this.date }
        });
      }
    },
    sortStatistics() {
      if(this.date != this.$route.query.date || this.selectBook != this.$route.query.book_id){
        this.$router.push({
          name: this.$route.name,
          query: { book_id: this.selectBook, date: this.date }
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      statistics: "cabinet_statistics/statistics",
      books: "cabinet_statistics/books",
      all_count: "cabinet_statistics/all_count",
      all_honorarium: "cabinet_statistics/all_honorarium",
    }),
    dateRangeText() {
      //   if (this.date.length > 1) {
      //     return this.date.join(" ~ ");
      //   }
      return this.date;
    }
  },
  mounted() {
    this.selectBook = this.$route.query.book_id
      ? this.$route.query.book_id
      : "all";
    if (this.selectBook != "all") {
      this.selectBook = +this.selectBook;
    }
    this.date = this.$route.query.date
      ? this.$route.query.date
      : new Date().toISOString().substr(0, 7);

    this.getStatistics();
  }
};
</script>
